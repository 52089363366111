import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import iframe from './modules/iframe'
import planDrill from './modules/planDrill'
import exterior from './modules/exterior'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    iframe,
    planDrill,
    exterior
  }
})
