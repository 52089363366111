<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { proxy, unProxy } from 'ajax-hook'

export default {
  created() {
    this.listenLoad3DTitle()
  },

  mounted() {
    this.initUserInfo()
  },

  beforeDestroy() {
    unProxy()
  },

  methods: {
    ...mapActions('user', ['initUserInfo']),

    /**
     * 华为云OBS 请求静态资源, 链接带有特殊符号会请求失败, 需要转义
     */
    listenLoad3DTitle() {
      proxy({
        //请求发起前进入
        onRequest: (config, handler) => {
          const url = config.url

          if (url.indexOf('obs.cn-north-4.myhuaweicloud.com') > -1) {
            config.url = url.replace(/\+/g, '%2B')
          }
          handler.next(config)
        },
        //请求发生错误时进入，比如超时；注意，不包括http状态码错误，如404仍然会认为请求成功
        onError: (err, handler) => handler.next(err),
        //请求成功后进入
        onResponse: (response, handler) => handler.next(response)
      })
    }
  }
}
</script>

<style>
:root {
  --zk-color: #18cffd;
  --zk-text: #18cffd;

  --zk-min-size: 12px;
  --zk-size: 14px;
  --zk-l-size: 16px;
  --zk-xl-size: 18px;
}
</style>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: var(--zk-size);
  box-sizing: border-box;
}
</style>
