<template>
  <div class="pop-background">
    <div class="pop-body" :style="{ width, height, backgroundColor }">
      <div class="pop-title">
        <div class="text-box">
          <span>{{ title }}</span>
          <i class="el-icon-close" @click.stop="$emit('close')" />
          <div class="decorate">
            <i />
            <i />
          </div>
        </div>
        <i class="line"></i>
      </div>

      <div class="pop-content">
        <slot></slot>
      </div>

      <div class="pop-button" v-if="!hideFooter">
        <el-button v-if="showCancel" type="zk-primary" class="item-btn" @click.stop="$emit('close')" :disabled="isLoading">取消</el-button>
        <el-button type="zk-primary" class="item-btn" @click.stop="$emit('submit')" :disabled="isLoading"><i v-if="isLoading" class="el-icon-loading" /> {{ btnTitle }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '500px'
    },
    height: {
      type: String,
      default: 'auto'
    },
    backgroundColor: {
      type: String,
      default: 'rgba(2, 15, 33, 0.8)'
    },
    title: {
      type: String,
      default: ''
    },
    btnTitle: {
      type: String,
      default: '确定'
    },

    hideFooter: {
      type: Boolean,
      default: false
    },

    showCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    showLoading() {
      this.isLoading = true
    },
    hideLoading() {
      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
@mixin flex($type: null, $dir: null) {
  display: flex;
  align-items: center;
  @if $type {
    justify-content: $type;
  }
  @if $dir {
    flex-direction: $dir;
  }
}

@mixin position($type: null, $top: 0, $left: 0, $right: null, $bottom: null) {
  position: $type;
  top: $top;
  left: $left;
  @if $bottom {
    bottom: $bottom;
  }
  @if $right {
    right: $right;
  }
}

@mixin positionCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin opacityHover() {
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.pop-background {
  @include position(fixed, 0, 0, 0, 0);
  z-index: 10;
  background-color: #0112265b;
  .pop-body {
    @include positionCenter();
    padding: 20px 25px;
    outline: 1px solid #1077ba;
    box-sizing: border-box;
    .pop-title {
      .text-box {
        @include flex(space-between);
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        color: #fff;
        .el-icon-close {
          font-size: 24px;
          color: #009fff;
          @include opacityHover();
        }
        .decorate {
          position: absolute;
          top: 2px;
          left: 4px;
          @include position(absolute, 2px, 4px);
          > i {
            width: 7px;
            height: 7px;
            display: block;
            border: 1px solid #d59e40;
            border-radius: 50%;
            &:last-child {
              border-color: #19556e;
              margin-top: 2px;
            }
          }
        }
      }
      .line {
        border: 1px solid #1077ba;
        border-top: none;
        width: 100%;
        height: 7px;
        display: block;
        background-color: #1076ba15;
      }
    }
    .pop-content {
      margin-top: 10px;
      &::v-deep .el-form-item__label {
        color: #fff;
      }
      position: relative;
      padding: 10px 0;
      // .loading-bgc {
      //   @include position(absolute, 0, 0, 0, 0);
      //   background-color: #020f2146;
      // }
    }

    .pop-button {
      text-align: right;
    }
  }
}
</style>
