import Vue from 'vue'

// EventBus
import EventBus from '@/utils/bus'
Vue.prototype.$EventBus = EventBus

// element-ui
import './element'

import '@/assets/styles/index.scss'

// 三维框架
import ZK from '@gis/zk-base'
import '@gis/zk-base/dist/zk.base.min.css'

ZK.baseUrl = 'https://socialize.zkyunteng.cn/rely/Cesium/resources/'
ZK.init(() => {
  window.Cesium = ZK.Namespace.Cesium
})

// 二维库
// import fabric from 'fabric'
// Vue.use(fabric)

// 全局组件
import components from '@/components/index'
Vue.use(components)

// js原生扩展
import './expand.js'
