export default {
  namespaced: true,

  state: {
    isRelevance: null
  },

  mutations: {
    /**
     * 设置iframe 获取建筑物，层级
     * @param {*} state
     * @param {Boolean} status
     */
    SET_IFRAME_ISRELEVANCE(state, data = null) {
      state.isRelevance = data
    }
  }
}
