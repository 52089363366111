import { POST } from '@/utils/http'

const PORT_USERS = '/service-users'

// 登录
export function login(params) {
  return POST({ url: `${PORT_USERS}/userBase/userLogin`, params })
}

// 登出
export function logout() {
  return POST({ url: `${PORT_USERS}/userBase/logout` })
}

export default {
  login,
  logout
}
