import Vue from 'vue'
import VueRouter from 'vue-router'
import Storage from '@/utils/Storage'
const userStorage = new Storage('userinfo')

Vue.use(VueRouter)

const Login = () => import('@/views/Login/index.vue')
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home/index.vue')

/**
 * @description Deduction 推演模块
 */
const Deduction = () => import(/* webpackChunkName: "deduction" */ '@/views/deduction/index.vue')
const Entrance = () => import(/* webpackChunkName: "deduction" */ '@/views/deduction/entrance/index.vue')
const Operate = () => import(/* webpackChunkName: "deduction" */ '@/views/deduction/operate/index.vue')

/**
 * @description CheckPlan 查看推演模块
 */
const CheckPlan = () => import(/* webpackChunkName:"checkPlan" */ '@/views/checkPlan/index.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/deduction',
    name: 'Deduction',
    redirect: '/deduction/entrance',
    component: Deduction,
    children: [
      {
        path: 'entrance',
        name: 'Entrance',
        component: Entrance
      },
      {
        path: 'operate',
        name: 'Operate',
        component: Operate
      }
    ]
  },
  {
    path: '/checkPlan',
    name: 'CheckPlan',
    component: CheckPlan
  }
]

const router = new VueRouter({
  routes
})

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  const data = userStorage.getStorage()

  if (data) {
    if (to.path === '/login') return next({ path: '/' })
    return next()
  }

  if (whiteList.includes(to.path)) return next()
  return next('/login')
})

export default router
