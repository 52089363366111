import Vue from 'vue'
import './styles.scss'

import {
  Avatar,
  Button,
  Carousel,
  CarouselItem,
  Select,
  Option,
  Dialog,
  Loading,
  Input,
  Autocomplete,
  Image,
  Message,
  MessageBox,
  Table,
  TableColumn,
  Icon,
  Empty,
  CheckboxGroup,
  Checkbox,
  Tabs,
  TabPane,
  Pagination,
  Popover,
  Form,
  FormItem,
  Tooltip
} from 'element-ui'

// 按需加载element-ui组件
;[
  Avatar,
  Button,
  Carousel,
  CarouselItem,
  Select,
  Option,
  Dialog,
  Input,
  Autocomplete,
  Image,
  Loading,
  Table,
  TableColumn,
  Icon,
  Empty,
  CheckboxGroup,
  Checkbox,
  Tabs,
  TabPane,
  Pagination,
  Popover,
  Form,
  FormItem,
  Tooltip
].forEach(item => Vue.use(item))

Vue.prototype.$loading = Loading.service

Vue.prototype.$message = function (msg) {
  return Message({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1000
  })
}

Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1000
  })
}

Vue.prototype.$confirm = MessageBox.confirm

Vue.prototype.$prompt = MessageBox.prompt
