import SparkMD5 from 'spark-md5'

export const isBoolean = obj => typeof obj === 'boolean'
export const isInteger = function (obj) {
  return (obj | 0) === obj
}
export const isNull = function (obj) {
  return obj === undefined || obj === null
}
export const isNoValue = function (obj) {
  return obj === undefined || obj === null || obj === ''
}
export const isEmptyObj = (obj) => {
  for (let obj1 in obj) {
    return false
  }
  return true
}

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
export function isArray(val) {
  return toString.call(val) === '[object Array]'
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
export function isObject(val) {
  return val !== null && typeof val === 'object'
}
/**
 * Determine if a value is a String
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a String, otherwise false
 */
export function isString(val) {
  return typeof val === 'string'
}
/**
 * Determine if a value is a Number
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Number, otherwise false
 */
export function isNumber(val) {
  return typeof val === 'number'
}
/**
 * Determine if a value is undefined
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if the value is undefined, otherwise false
 */
export function isUndefined(val) {
  return typeof val === 'undefined'
}
/**
 * Determine if a value is a Function
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Function, otherwise false
 */
export function isFunction(val) {
  return toString.call(val) === '[object Function]'
}

export function deepClone(source) {
  if (!source && typeof source !== 'object' || !source.constructor) {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/*
 * 将对象转为url参数字符串【主要用于delete请求，需处理请求，伍志强不要乱删】
 * @export
 * @param {*} obj
 * @returns string
 */
export function objToUrl(obj) {
  if (!obj) {
    return ''
  }
  const strArr = []
  Object.entries(obj).map(([key, value]) => {
    strArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    return value
  })
  return strArr.join('&')
}

export function getUrlParams(url) {
  const data = url.split('?')
  const params = {}
  if (data[1]) {
    data[1].split('&').forEach((string) => {
      const data = string.split('=')
      params[data[0]] = data[1]
    })
  }
  return {
    rootUrl: data[0],
    params
  }
}

// 校验手机格式
export function phoneFun(phones) {
  // return /^1[3|4|5|8][0-9]\d{4,8}$/.test(phones)
  let myreg = /^1[3|4|5|8][0-9]\d{4,8}$/
  if (!myreg.test(phones)) {
    return false
  }
  return true
}

/* 唯一标识码 */
const UDIDCODE = 'social_plan_v2_'

/**
 * @desc session存储
 * @export sessionSet
 * @param key key键
 * @param value 值
 */
export function sessionSet(key, value, nostringfy) {
  if (typeof value === 'object' && (!nostringfy || nostringfy !== 'nostringfy')) {
    value = JSON.stringify(value)
  }
  sessionStorage.setItem(UDIDCODE + key, value)
}

/**
 * @desc session获取
 * @export sessionGet
 * @param key key键
 */
export function sessionGet(key) {
  const value = sessionStorage.getItem(UDIDCODE + key) || ''
  try {
    const val = JSON.parse(value)
    if ('number' === typeof val) {
      return value
    }
    return val
  } catch (e) {
    return value
  }
}

/**
 * @desc session移除
 * @export sessionRemove
 * @param key key键
 */
export function sessionRemove(key) {
  sessionStorage.removeItem(UDIDCODE + key)
}

/**
 * @desc session清空
 * @export sessionClearAll
 */
export function sessionClearAll() {
  sessionStorage.clear()
}

// 格式化日期
export const getTime = (date, withSec) => {
  if (date === null) return
  date = new Date(date)
  if (date.toString() === 'Invalid Date') return
  let hour = date.getHours()
  let min = date.getMinutes()
  hour = hour > 9 ? hour : `0${hour}`
  min = min > 9 ? min : `0${min}`
  let result = `${hour}:${min}`
  if (withSec) {
    let sec = date.getSeconds()
    sec = sec > 9 ? sec : `0${sec}`
    result = `${hour}:${min}:${sec}`
  }
  return result
}
export const formatDate = (date, format) => {
  if (date === null) return
  date = new Date(date)
  if (date.toString() === 'Invalid Date') return
  let m = (date.getMonth() + 1).toString()
  let d = date.getDate().toString()
  m = m.length >= 2 ? m : '0' + m
  d = d.length >= 2 ? d : '0' + d
  if (!format) return date.getFullYear() + '-' + m + '-' + d
  if (format === 'Array') return [date.getFullYear(), m + '/' + d]
}
export const formatDateAndTime = (date, withSec) => {
  if (date === null) return
  date = new Date(date)
  if (date.toString() === 'Invalid Date') return
  const dateStr = formatDate(date)
  const timeStr = getTime(date, withSec)
  return `${dateStr} ${timeStr}`
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 */
export function debounce(func, wait) {
  let timeout = null
  wait = wait || 300
  return function () {
    let context = this
    let args = arguments
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(context, args)
    }, wait)
    // if (callNow) callback.apply(this, [callback, ms])
  }
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 */
export function throttle(func, wait) {
  let timeout = null
  return function () {
    let context = this
    let args = arguments
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        func.apply(context, args)
      }, wait)
    }
  }
}

/* eslint-disable */
export var GPS = {
  PI: 3.14159265358979324,
  x_pi: (3.14159265358979324 * 3000.0) / 180.0,
  delta(lat, lon) {
    //
    // a = 6378245.0, 1/f = 298.3
    // b = a * (1 - f)
    // ee = (a^2 - b^2) / a^2;
    let a = 6378245.0 //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
    let ee = 0.00669342162296594323 //  ee: 椭球的偏心率。
    let dLat = this.transformLat(lon - 105.0, lat - 35.0)
    let dLon = this.transformLon(lon - 105.0, lat - 35.0)
    let radLat = (lat / 180.0) * this.PI
    let magic = Math.sin(radLat)
    magic = 1 - ee * magic * magic
    let sqrtMagic = Math.sqrt(magic)
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * this.PI)
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * this.PI)
    return { lat: dLat, lon: dLon }
  },

  // WGS-84 to GCJ-02
  gcj_encrypt(wgsLat, wgsLon) {
    if (this.outOfChina(wgsLat, wgsLon)) {
      return { lat: wgsLat, lon: wgsLon }
    }
    let d = this.delta(wgsLat, wgsLon)
    return { lat: wgsLat + d.lat, lon: wgsLon + d.lon }
  },
  // GCJ-02 to WGS-84
  gcj_decrypt(gcjLat, gcjLon) {
    if (this.outOfChina(gcjLat, gcjLon)) {
      return { lat: gcjLat, lon: gcjLon }
    }
    let d = this.delta(gcjLat, gcjLon)
    return { lat: gcjLat - d.lat, lon: gcjLon - d.lon }
  },
  // GCJ-02 to WGS-84 exactly
  gcj_decrypt_exact(gcjLat, gcjLon) {
    let initDelta = 0.01
    let threshold = 0.000000001
    let dLat = initDelta
    let dLon = initDelta
    let mLat = gcjLat - dLat
    let mLon = gcjLon - dLon
    let pLat = gcjLat + dLat
    let pLon = gcjLon + dLon
    let wgsLat
    let wgsLon
    let i = 0
    while (1) {
      wgsLat = (mLat + pLat) / 2
      wgsLon = (mLon + pLon) / 2
      let tmp = this.gcj_encrypt(wgsLat, wgsLon)
      dLat = tmp.lat - gcjLat
      dLon = tmp.lon - gcjLon
      if (Math.abs(dLat) < threshold && Math.abs(dLon) < threshold) {
        break
      }
      if (dLat > 0) pLat = wgsLat
      else mLat = wgsLat
      if (dLon > 0) pLon = wgsLon
      else mLon = wgsLon

      if (++i > 10000) break
    }
    return { lat: wgsLat, lon: wgsLon }
  },
  // GCJ-02 to BD-09
  bd_encrypt(gcjLat, gcjLon) {
    let x = gcjLon
    let y = gcjLat
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi)
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi)
    let bdLon = z * Math.cos(theta) + 0.0065
    let bdLat = z * Math.sin(theta) + 0.006
    return { lat: bdLat, lon: bdLon }
  },
  // BD-09 to GCJ-02
  bd_decrypt(bdLat, bdLon) {
    let x = bdLon - 0.0065
    let y = bdLat - 0.006
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi)
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi)
    let gcjLon = z * Math.cos(theta)
    let gcjLat = z * Math.sin(theta)
    return { lat: gcjLat, lon: gcjLon }
  },
  // WGS-84 to Web mercator
  // mercatorLat -> y mercatorLon -> x
  mercator_encrypt(wgsLat, wgsLon) {
    let x = (wgsLon * 20037508.34) / 180
    let y = Math.log(Math.tan(((90 + wgsLat) * this.PI) / 360)) / (this.PI / 180)
    y = (y * 20037508.34) / 180
    return { lat: y, lon: x }
  },
  // Web mercator to WGS-84
  // mercatorLat -> y mercatorLon -> x
  mercator_decrypt(mercatorLat, mercatorLon) {
    let x = (mercatorLon / 20037508.34) * 180
    let y = (mercatorLat / 20037508.34) * 180
    y = (180 / this.PI) * (2 * Math.atan(Math.exp((y * this.PI) / 180.0)) - this.PI / 2)
    return { lat: y, lon: x }
  },
  // two point's distance
  distance(latA, lonA, latB, lonB) {
    let earthR = 6371000
    let x = Math.cos((latA * this.PI) / 180.0) * Math.cos((latB * this.PI) / 180.0) * Math.cos(((lonA - lonB) * this.PI) / 180)
    let y = Math.sin((latA * this.PI) / 180.0) * Math.sin((latB * this.PI) / 180.0)
    let s = x + y
    if (s > 1) s = 1
    if (s < -1) s = -1
    let alpha = Math.acos(s)
    let distance = alpha * earthR
    return distance
  },
  outOfChina(lat, lon) {
    if (lon < 72.004 || lon > 137.8347) {
      return true
    }
    if (lat < 0.8293 || lat > 55.8271) {
      return true
    }
    return false
  },
  transformLat(x, y) {
    let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
    ret += ((20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0) / 3.0
    ret += ((20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin((y / 3.0) * this.PI)) * 2.0) / 3.0
    ret += ((160.0 * Math.sin((y / 12.0) * this.PI) + 320 * Math.sin((y * this.PI) / 30.0)) * 2.0) / 3.0
    return ret
  },
  transformLon(x, y) {
    let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
    ret += ((20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0) / 3.0
    ret += ((20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin((x / 3.0) * this.PI)) * 2.0) / 3.0
    ret += ((150.0 * Math.sin((x / 12.0) * this.PI) + 300.0 * Math.sin((x / 30.0) * this.PI)) * 2.0) / 3.0
    return ret
  }
}
/* eslint-enable */

export function uploadPreprocess(file) {
  let spark = new SparkMD5()
  let fileRender = new FileReader()
  fileRender.readAsBinaryString(file)
  return new Promise((resolve) => {
    fileRender.onload = (e) => {
      spark.appendBinary(e.target.result)
      let hexHash = spark.end()
      // let formData = new FormData()
      // formData.append('file', file)
      // formData.append('md5', hexHash)
      console.log(hexHash)
      resolve({
        md5: hexHash,
        file
      })
    }
  })
}

export function dataURLtoFile(dataurl, filename = 'file') {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, { type: mime })
}

//文件流打包下载
export function filePackDownload(dataurl, fileName, fileType = 'data:application/zip;base64,') {
  const file = dataURLtoFile(fileType + dataurl)

  let aLink = document.createElement('a')
  let evt = document.createEvent('HTMLEvents')
  evt.initEvent('click', true, true) //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
  aLink.download = fileName
  aLink.href = URL.createObjectURL(file)
  aLink.style.display = 'none'
  aLink.click()
}

//根据路径下载文件
export function downLoadUrl(url) {
  let dom = document.createElement('a')
  dom.href = url
  dom.click()
  dom = null
}

//获取视频内存大小与时长
export function getFileSizeData(file) {
  return new Promise((resolve, reject) => {
    if (!file) reject('未找到文件对象')
    const { size, type } = file
    const fileSize = size / 1024 < 1024 ? `${(size / 1024).toFixed(1)}KB` : `${(size / 1024 ** 2).toFixed(1)}MB`

    if (type !== 'video/mp4') {
      resolve({ size: fileSize })
    }

    let reader = new FileReader()
    reader.onload = function () {
      let videoDom = document.createElement('video')
      videoDom.onloadeddata = (e) => {
        const { duration } = e.path[0]
        const fileTimeLength = formateSeconds(duration)
        resolve({
          size: fileSize,
          time: fileTimeLength
        })
      }
      videoDom.src = reader.result
    }
    reader.readAsDataURL(file)
  })
}
//格式化时间单位为秒的时间
function formateSeconds(endTime) {
  let secondTime = parseInt(endTime) //将传入的秒的值转化为Number
  let min = 0 // 初始化分
  let h = 0 // 初始化小时
  if (secondTime > 60) {
    //如果秒数大于60，将秒数转换成整数
    min = parseInt(secondTime / 60) //获取分钟，除以60取整数，得到整数分钟
    secondTime = parseInt(secondTime % 60) //获取秒数，秒数取佘，得到整数秒数
    if (min > 60) {
      //如果分钟大于60，将分钟转换成小时
      h = parseInt(min / 60) //获取小时，获取分钟除以60，得到整数小时
      min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
    }
  }
  return `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
}
//从html文本中获取所有的图片地址
export function getImgSrcFromStr(str) {
  let imgReg = /<img.*?(?:>|\/>)/gi,
    srcReg = /src=['"]?([^'"]*)['"]?/i,
    arr = str.match(imgReg) || [],
    pictureList = ''
  for (let i = 0; i < arr.length; i++) {
    let src = arr[i].match(srcReg)
    pictureList += src[1] + ','
  }

  return escape2Html(pictureList)
}

function escape2Html(str) {
  const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
  return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, (all, t) => arrEntities[t])
}

const getType = param => Object.prototype.toString.call(param)
const isDate = date => getType(date) === '[object Date]'

export function getDate(time, ms) {
  let date
  if (typeof time === 'string') {
    // date = new Date(time.replace(/-/g, '/'))
    date = new Date(time)
  } else if (isDate(time)) {
    date = time
  } else {
    date = new Date(time * ms)
  }
  return date
}

//  format : y年m月d日 h:m:s
/**
 * @param {*} time 时间可以是毫秒，也可以是秒
 * @param {*} format 格式
 * @param {*} ms 是否毫秒
 */
export function formatLocalDate(time, format = 'Y年M月D日 h:m:s', ms = 1) {
  let date = getDate(time, ms)
  let args = {
    Y: date.getFullYear(),
    M: formatNumber(date.getMonth() + 1),
    D: formatNumber(date.getDate()),

    h: formatNumber(date.getHours()),
    m: formatNumber(date.getMinutes()),
    s: formatNumber(date.getSeconds())
  }

  return format.replace(/Y|M|D|h|m|s/g, match => args[match])
}

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function dateStamp() {
  const date = new Date()
  let YY = date.getFullYear()
  let MM = date.getMonth() + 1
  let DD = date.getDate()
  let mm = MM > 9 ? MM : `0${MM}`
  let dd = DD > 9 ? DD : `0${DD}`
  return YY + mm + dd
}

// 进入全屏
export function fullScreen() {
  const main = document.body

  if (main.requestFullscreen) {
    main.requestFullscreen()
  } else if (main.mozRequestFullScreen) {
    main.mozRequestFullScreen()
  } else if (main.webkitRequestFullScreen) {
    main.webkitRequestFullScreen()
  } else if (main.msRequestFullscreen) {
    main.msRequestFullscreen()
  }
}

// 退出全屏
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      resolve(e.target.result)
    }
    // readAsDataURL
    fileReader.readAsDataURL(blob)
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'))
    }
  })
}

// 人员图片裁剪为圆形
export function formatAvatar(url, size = 30) {
  let canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  let ctx = canvas.getContext('2d')
  let img = new Image()
  img.width = size
  img.height = size
  img.src = url
  img.setAttribute('crossOrigin', 'anonymous')
  return new Promise((resolve) => {
    img.onload = () => {
      ctx.beginPath()
      ctx.strokeStyle = 'rgba(0, 0, 0, 0)'
      ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, false)
      ctx.fill()
      ctx.clip()
      ctx.drawImage(img, 0, 0, size, size)
      resolve(canvas.toDataURL('image/png'))
    }
  })
}

export function isPc() {
  return !/Android|iPhone|SymbianOS|Windows Phone|iPad|iPod/.test(navigator.userAgent)
}

export function formatFileSize(size, pointLength, units) {
  const baseSize = 1024
  let unit
  units = units || ['B', 'KB', 'MB', 'GB']
  while ((unit = units.shift()) && size > baseSize) {
    size = size / baseSize
  }
  return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit
}

export default {
  getDate,
  formatLocalDate
}
