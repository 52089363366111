import userApi from '@/api/user'

import Storage from '@/utils/Storage'

// 登录信息，客户端写死
const loginData = {
  way: 'web',
  systemType: '1001001001'
}

export const userStorage = new Storage('userinfo')

export default {
  namespaced: true,

  state: {
    userInfo: {},
    userType: 0,
    userInfoId: 0,

    // 权限
    identity: {
      isFire: false
    },

    userCompanyInfo: {},
    isCompangInfoChange: false,

    // 当前单位
    currentCompanyInfo: {}
  },

  getters: {
    unitId: state => state.userCompanyInfo.id,
    organId: state => state.userInfo.organId
  },

  mutations: {
    SET_USER_INFO(state, val) {
      if (!val) return
      state.userInfo = val
      state.userType = val.userType
      state.userInfoId = val.id
    },

    QUIT_OUT(state) {
      state.userInfo = {}
      state.userType = 0
      state.userInfoId = 0
      state.userCompanyInfo = null
    },

    SET_USER_COMPANY_INFO(state, object = null) {
      state.userCompanyInfo = object
      localStorage.setItem('company.info', JSON.stringify(object))
    },

    SET_USER_COMPANY_IS_CHANGE(state, status = false) {
      state.isCompangInfoChange = status
    },

    SET_CURRENT_COMPANY_INFO(state, data) {
      state.currentCompanyInfo = data
    }
  },

  actions: {
    /**
     * @description 静默登录
     */
    silentLogin({ dispatch }, data) {
      return userApi
        .login({
          ...loginData,
          ...data
        })
        .then((data) => {
          userStorage.setStorage(JSON.stringify(data))
          dispatch('initUserInfo')
        })
    },

    initUserInfo({ commit }) {
      let userInfo = userStorage.getStorage()
      if (userInfo) {
        commit('SET_USER_INFO', JSON.parse(userInfo))
        return userInfo
      }
      return Promise.reject()
    },

    initCompanyInfo({ commit }) {
      let companyInfo = localStorage.getItem('company.info')
      if (companyInfo) {
        const data = JSON.parse(companyInfo)

        commit('SET_USER_COMPANY_INFO', data)
        return data
      }
    },

    logOut({ commit }, isRequest = true) {
      if (isRequest) {
        return userApi.logout().then(() => {
          commit('QUIT_OUT')
          userStorage.removeStorage()
        })
      }
      commit('QUIT_OUT')
      userStorage.removeStorage()
    }
  }
}
