import { sessionGet } from '@/utils/util'
import HtmlHostMsg from 'HtmlHostMsg'
import EventBus from '@/utils/bus'

export default {
  namespaced: true,

  state: {
    loadUrl: null, //'https://www.thingjs.com/s/55f8b7f65ab84509c59ca684?params=105b0f77fd24654d4eebc434e9'
    toolData: null,
    linkWaterData: null,
    moduleBuildId: null, //模型

    /* 导航的 */
    hideNavbar: sessionGet('route.name') && sessionGet('route.name') === 'Preliminary',
    currentNavKey: 0, //导航菜单的选中值
    currentCompanyInfo: null,
    screenUnitDraw: null,

    currentGradeIndex: 1, //阶段的选中值

    // 当前预案制作的保存状态，用于重置预案制作的数据
    currentPlanSaveStatus: {},

    enterInside: false,
    showOperation: false, //消防车操作窗口显隐
    isBackOutside: true, //内景是否显示返回室外按钮
    ishorizontal: false,
    showMiniMap: true
  },

  mutations: {
    /**
     * 设置iframe url模型地址
     * @param {*} state
     * @param {Boolean} status
     */
    SET_PLANDRILL_LOADURL(state, url = null) {
      state.loadUrl = url
    },
    /**
     * 设置iframe 模型buildId
     * @param {*} state
     * @param {Boolean} id
     */
    SET_PLANDRILL_BUILDID(state, id = null) {
      state.moduleBuildId = id
    },

    /**
     * 设置 预案制作的导航
     * @param {*} state
     * @param {Boolean} status
     */
    SET_PLANDRILL_NAV(state, status = false) {
      state.hideNavbar = status
    },
    /**
     * 设置 预案制作的导航
     * @param {*} state
     * @param {Boolean} status
     */
    SET_PLANDRILL_NAVINDEX(state, status = 0) {
      state.currentNavKey = status
    },
    /**
     * 设置 预案制作的阶段的选中值
     * @param {*} state
     * @param {Boolean} status
     */
    SET_PLANDRILL_CURRENTGRADEINDEX(state, status = 1) {
      state.currentGradeIndex = status
    },

    /**
     * 设置 预案制作时的单位信息
     * @param {*} state
     * @param {Boolean} status
     */
    SET_PLANDRILL_COMPANYINFO(state, object = null) {
      state.currentCompanyInfo = object
    },

    /**
     * 设置预案制作 截屏
     * @param {*} state
     * @param {Object} object
     */
    SET_PLAN_SCREEN(state, object = null) {
      state.screenUnitDraw = object
    },

    /**
     * 设置预案制作 路线数据
     * @param {*} state
     * @param {Object} object
     */
    SET_PLANDRILL_TOOL(state, object = null) {
      state.toolData = object
    },
    /**
     * 设置预案制作 水带
     * @param {*} state
     * @param {Object} object
     */
    SET_PLANDRILL_LINKWATER(state, object = null) {
      state.linkWaterData = object
    },

    /**
     * 储存当前预案制作的保存状态
     */
    SET_PLANRILL_SAVE_STATUS(state) {
      state.currentPlanSaveStatus[state.currentNavKey] = true
    },

    /**
     * 清空当前预案制作的保存状态
     */
    CLEAR_PLANRILL_SAVE_STATUS(state) {
      state.currentPlanSaveStatus = {}
    },

    /**
     * 设置内外景状态值
     */
    SET_ENTERINSIDE(state, enterInside) {
      state.enterInside = enterInside
    },
    /**
     * 设置消防车右侧操作窗口显隐
     */
    SET_SHOWOPERATION_SHOW(state, isShow) {
      state.showOperation = isShow
    },
    /**
     * 设置返回室外控制按钮
     */
    SET_BACK_OUTSIDE(state, isShow) {
      state.isBackOutside = isShow
    },

    /**
     * 网页是否处于横屏模式
     */
    SET_SCREEN_ORIENTATION(state, ishorizontal) {
      state.ishorizontal = ishorizontal
    },

    /**
     * 设置minimap显示隐藏
     */
    SET_MINIMAP_SHOW(state, isShow) {
      state.showMiniMap = isShow
    }
  },

  actions: {
    setThingModel({ state, commit }, data) {
      let { modelUrl, buildId, modelKey, id } = data

      buildId = buildId || id

      // 完全一致，不需要
      if (state.loadUrl === modelUrl && +state.moduleBuildId === +buildId) return

      // 加载
      commit('SET_PLANDRILL_BUILDID', buildId || id)
      commit('SET_PLANDRILL_LOADURL', modelUrl)

      // 每设置一次，重新传值一次
      HtmlHostMsg.event('load', { url: modelUrl, reload: true, useExpend: false, drawEndClick: 0 })
      if (modelKey) {
        HtmlHostMsg.event('floor', { modelKey })
      }
    },

    changeSceneStatus({ commit }, isInside) {
      commit('SET_ENTERINSIDE', isInside)
      EventBus.$emit('change.iframe.model.size', !isInside)
      EventBus.$emit('change.key.unit.center.map.status', isInside)
    },

    OUT_PLANDRILL({ commit }) {
      commit('SET_PLANDRILL_NAVINDEX')
      commit('SET_PLANDRILL_CURRENTGRADEINDEX')
      commit('SET_PLANDRILL_NAV')
    },

    SHOW_EVALUATION({ commit }, bol) {
      commit('SET_SHOW_EVALUATION', bol)
    },

    resetThingModel({ commit }) {
      // 内部模型清除地址
      commit('SET_PLANDRILL_BUILDID', null)
      commit('SET_PLANDRILL_LOADURL', null)
    }
  }
}
