import axios from 'axios' // 引入axios
import Store from '@/store'
import apiCode from './api-code'
import router from '@/router'
import { Message, MessageBox } from 'element-ui'

const errorLog = (message) => {
  return Message.error({
    message,
    duration: 1000
  })
}

let timer

axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  (config) => {
    const token = Store.state.user.userInfo.accessToken

    token && (config.headers.Authorization = 'Bearer ' + token)

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  error => error
)

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为0，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === apiCode.STATUS_SUCCESS) {
      if (response.data.code === apiCode.SUCCESS || response.data.status === 1000) {
        return Promise.resolve(response.data)
      } else if (response.data.code === apiCode.GENERAL_ERROR) {
        errorLog(response.data.data)
        return Promise.reject(response)
      }
      errorLog(response.data.code)
      return Promise.reject(response)
    }
    return Promise.reject(response)
  },
  (error) => {
    console.log(error)

    const handlerFail = () => {
      Store.dispatch('user/logOut', false).then(() => {
        setTimeout(() => {
          router.replace({ path: '/login' })
        }, 1000)
      })
    }

    const { code, data } = error.response.data

    switch (error.response.status) {
      case apiCode.STATUS_NO_ACCESS:
        if (code === apiCode.TOKEN_INVALID || /status 401/.test(data)) {
          if (!timer) {
            timer = setTimeout(() => {
              clearTimeout(timer)
              timer = null
            }, 3000)

            MessageBox.confirm('你的账号在另一地点登录，已被迫退出。如非本人操作，建议修改密码。', '警告', {
              showCancelButton: false,
              type: 'error',
              callback: handlerFail
            })
          }
          return
        } else if (router.history.current.path === '/login') {
          errorLog('用户名或密码错误')
        } else {
          handlerFail()
        }
        break
      // 404请求不存在
      case apiCode.ROUTING_ERROR:
        errorLog('网络请求不存在')
        break
      // 502
      case apiCode.SERVER_CRASH:
        errorLog('服务器崩溃了')
        break
      // 其他错误，直接抛出错误提示
      default:
        errorLog(error.response.data.message || '网络繁忙，请稍后再试')
    }
    return Promise.reject(error.response)
  }
)
