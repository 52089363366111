const KEY = 'electronic-sandtable_storage_'

export default class Storage {
  constructor(key) {
    this.key = KEY + key
  }

  getStorage() {
    return localStorage.getItem(this.key)
  }

  setStorage(data) {
    localStorage.setItem(this.key, data)
  }

  removeStorage() {
    localStorage.removeItem(this.key)
  }
}
