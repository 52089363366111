export default {
  namespaced: true,

  state: {
    entityData: null
  },

  mutations: {
    //设置当前点击实体的数据
    SET_ENTITY_DATA(state, obj) {
      state.entityData = obj
    }
  }
}
