import axios from 'axios' // 引入axios
import './interceptors'
axios.defaults.baseURL = getBaseUrl()
/**
 *获取服务器地址
 */
export function getBaseUrl(isWs) {
  const env = process.env.NODE_ENV

  const HOSTS = {
    development: `192.168.1.86${isWs ? '/wss' : ':8007'}`,
    local: 'a3011451d5.zicp.vip',
    production: `fp.digital-plan.cn${isWs ? '/wss' : ''}`
  }

  const certificate = `${isWs ? 'ws' : 'http'}${env === 'production' ? 's' : ''}://`

  return certificate + HOSTS[env]
}

export function UPLOAD(formData, config) {
  return axios({
    baseURL: 'https://fastdfs.equiplink.cn/zuul',
    url: '/service-fastdfs/upload',
    data: formData,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    ...config
  }).then(res => res.data)
}

/**
 * delete 方法，delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function DELETE({ url, params }) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function GET({ url, params }) {
  axios.defaults.baseURL = getBaseUrl()
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * head方法，对应head请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function HEAD({ url, params }) {
  return new Promise((resolve, reject) => {
    axios
      .head(url, {
        params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function POST({ url, params }) {
  if (url === '/service-fastdfs/upload') {
    axios.defaults.baseURL = 'https://fastdfs.equiplink.cn'
  } else {
    axios.defaults.baseURL = getBaseUrl()
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function PUT({ url, params }) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function PATCH({ url, params }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
